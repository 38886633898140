<template>
  <div class="home">
    <b-container fluid class="vh-100">
      <b-row class="vh-100" align-v="center">
        <b-col class="text-center">
          <h1 v-if="!voted" @click.prevent="requestFullscreen()">Ar skaniai pavalgėte?</h1>
          <div class="d-flex" v-if="!voted">
            <a class="vote-btn" @click="setVote(1)">
              <b-icon-star-fill class="r1" font-scale="3"></b-icon-star-fill>
              <label class="d-block pt-3 text-body">Labai blogai</label>
            </a>
            <a class="vote-btn" @click="setVote(2)">
              <b-icon-star-fill class="r2" font-scale="3"></b-icon-star-fill>
              <label class="d-block pt-3 text-body">Blogai</label>
            </a>
            <a class="vote-btn" @click="setVote(3)">
              <b-icon-star-fill class="r3" font-scale="3"></b-icon-star-fill>
              <label class="d-block pt-3 text-body">Normaliai</label>
            </a>
            <a class="vote-btn" @click="setVote(4)">
              <b-icon-star-fill class="r4" font-scale="3"></b-icon-star-fill>
              <label class="d-block pt-3 text-body">Gerai</label>
            </a>
            <a class="vote-btn" @click="setVote(5)">
              <b-icon-star-fill class="r5" font-scale="3"></b-icon-star-fill>
              <label class="d-block pt-3 text-body">Puikiai</label>
            </a>
          </div>
          <div v-else>
            <b-alert variant="success" show>Dėkojame už įvertinimą</b-alert>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="version text-muted position-absolute">v0.0.9</div>
  </div>
</template>

<script>
import { Timestamp, db } from "./../firebase";
import { BIconStarFill, BAlert } from "bootstrap-vue";

export default {
  name: "Home",
  components: {
    BIconStarFill,
    BAlert
  },
  data() {
    return {
      votes: [],
      voted: false
    };
  },
  methods: {
    setVote(value) {
      this.voted = true;
      this.votes.push(value);
      // console.log('votes up', this.votes);
      // this.rating = value;

      db.collection("likes").add({
        rating: value,
        date: Timestamp.fromDate(new Date()),
        place: "Baze"
      });

      setTimeout(() => {
        this.voted = false;
      }, 1000);
    },
    requestFullscreen() {
      document.querySelector(".home").requestFullscreen();
    }
  }
};
</script>
<style scoped>
.vote-btn,
label {
  color: darkgray;
  opacity: 0.8;
}
.vote-btn:hover,
label:hover {
  opacity: 1;
  cursor: pointer;
  color: gray;
}
.d-flex {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: 60px auto;
}
.center {
  margin: 0 auto 5px;
}
.r5 {
  color: #DC2626;
}
.r4 {
  color: #EF4444;
}
.r3 {
  color: #F87171;
}
.r2 {
  color: #FCA5A5;
}
.r1 {
  color: #FECACA;
}
.version {
  bottom: 10px;
  left: 10px;
  opacity: 0.5;
  font-size: 6px;
}
</style>
