// import { initializeApp } from 'firebase';
import firebase from "firebase/app";
import "firebase/firestore";

const app = firebase.initializeApp({
  apiKey: "AIzaSyAmNuY59xeE7O6m_SpelZSxBkvgdIGNm7U",
  authDomain: "liker-1c0a8.firebaseapp.com",
  projectId: "liker-1c0a8",
  storageBucket: "liker-1c0a8.appspot.com",
  messagingSenderId: "979247091793",
  appId: "1:979247091793:web:4df68f4b251de5e95e2870",
  measurementId: "G-990TKH468Y"
});

export const db = app.firestore();

db.settings({ timestampsInSnapshots: true });

const { Timestamp } = firebase.firestore;
export { Timestamp };
